import * as React from 'react';
import { PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { AllAuthor, PageContextProps } from '../../utils/graphql';
import DefaultLayout from '../layouts/default-layout';
import AuthorsList from './authors-list';
import NewsFooter from './news-footer';

type AuthorsQueryProps = {
  authors: AllAuthor;
};

type AuthorsProps = PageProps<AuthorsQueryProps, PageContextProps>;

const NewsAuthors: React.VoidFunctionComponent<AuthorsProps> = ({
  data: {
    authors: { nodes },
  },
  pageContext,
}) => {
  const { t } = useTranslation('news');

  return (
    <DefaultLayout
      headTitle={t('authors.head-title')}
      headDescription={t('authors.head-description')}
      lang={pageContext.locale}
      wrapInContainer
    >
      <h1 id="newsAuthors" className="text-6xl text-reacteev-blue">
        {t('authors.title')}
      </h1>
      <AuthorsList authors={nodes} />
      <NewsFooter pageContext={pageContext} />
    </DefaultLayout>
  );
};

export default NewsAuthors;
