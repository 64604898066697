import { graphql } from 'gatsby';
import NewsAuthors from '../components/news/news-authors';

export default NewsAuthors;

export const query = graphql`
  query ($locale: String!, $postsPublishedFilter: [Boolean]) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    authors: allAuthorJson(
      filter: {
        posts: { elemMatch: { frontmatter: { Published: { in: $postsPublishedFilter } } } }
      }
    ) {
      nodes {
        fullName
        normalizedFullName
        bio(locale: $locale)
        avatar {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 48, height: 48, placeholder: NONE)
          }
        }
      }
    }
  }
`;
