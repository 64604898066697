import * as React from 'react';

type MailSubscriptionProps = {
  locale: string;
};

const MailSubscription: React.VoidFunctionComponent<MailSubscriptionProps> = () => {
  return null;
};

export default MailSubscription;
