import React from 'react';
import { Author as AuthorType } from '../../utils/graphql';
import Author from './author';

type AuthorsProps = {
  authors: AuthorType[];
};

const AuthorsList: React.VoidFunctionComponent<AuthorsProps> = ({ authors }) => (
  <>
    {authors.map((author) => (
      <Author key={author.fullName} author={author} />
    ))}
  </>
);

export default AuthorsList;
